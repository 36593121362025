import { sendClickHouseAnalytic } from '@shared/api/analytics/template'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'

type TypeDefaultDataOutput = PickAnalytic<'object_id' | 'object_type'>
const getDefaultDataNews = (news: TypeMappingNewsOutput): TypeDefaultDataOutput => {
  const mappingType = {
    'plani-na-nedelu': 'selection',
    podborki: 'selection',
    'avtorskaia-kolonka': 'article',
    obzory: 'article',
    novosti: 'article',
    interviu: 'interviu',
  }

  const result: TypeDefaultDataOutput = {
    object_id: news.id,
    object_type: mappingType[news.type.slug] || 'article',
  }

  return result
}

type TypeAnalyticRestCard = PickAnalytic<
  | 'restaurant_name'
  | 'object_type'
  | 'object_id'
  | 'has_reserve'
  | 'afisha_selected'
  | 'is_promo_card'
  | 'has_promo_offer',
  'rating'
>
const getDefaultDataRest = (rest: TypeMappingRestOutput): TypeAnalyticRestCard => {
  const result: TypeAnalyticRestCard = {
    restaurant_name: rest.name,
    object_type: 'restaurant',
    object_id: rest.id,
    has_reserve: rest.booking.afisha.active || rest.booking.tomesto.active,
    afisha_selected: rest.categories.selection,
    is_promo_card: rest.categories.advert,
    has_promo_offer: !!rest.offers.special || !!rest.offers.news,
  }

  if (rest.feedback.rating) {
    result.rating = rest.feedback.rating.toFixed(1)
  }

  return result
}

type TypeDefaultProps = PickAnalytic<'section_index' | 'section_name'>
export const EGGNews = {
  card_click: (news: TypeMappingNewsOutput, props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'news_card',
      ...getDefaultDataNews(news),
      ...props,
    }),

  card_view: (news: TypeMappingNewsOutput, props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_view',
      element_type: 'news_card',
      ...getDefaultDataNews(news),
      ...props,
    }),

  click_add_favorite: (news: TypeMappingNewsOutput, props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'news_card',
      ...getDefaultDataNews(news),
      ...props,
    }),

  click_remove_favorite: (news: TypeMappingNewsOutput, props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'news_card',
      ...getDefaultDataNews(news),
      ...props,
    }),

  rest_name_link_view: (rest: TypeMappingRestOutput, props: PickAnalytic<'section_name'>) =>
    // section_name = 'title' | 'widget' | 'paragraph'
    sendClickHouseAnalytic({
      event_name: 'element_view',
      element_type: 'rest_card',
      ...getDefaultDataRest(rest),
      ...props,
    }),

  rest_name_link_click: (rest: TypeMappingRestOutput, props: PickAnalytic<'section_name'>) =>
    // section_name = 'title' | 'widget' | 'paragraph'
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'rest_card',
      ...getDefaultDataRest(rest),
      ...props,
    }),
}
