import React, { FC } from 'react'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useGetABTests } from '@shared/lib/hooks/useGetABTests'
import { QuickBookingRestC } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRestC'
import { QuickBookingRestB } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRestB'

interface Props {
  className?: string
}

const QuickBookingRest: FC<Props> = (props) => {
  const ABTests = useGetABTests()
  const ABTestBooking = ABTests.find((test) => test.name === 'AB_OPEN_BOOKING_1')
  if (!ABTestBooking || ABTestBooking?.group === 'A') return

  // AB enabled for group B
  if (ABTestBooking) {
    ABTestBooking.group = 'B'
  }

  return (
    <ErrorBoundary>
      {ABTestBooking.group === 'C' && <QuickBookingRestC {...props} />}
      {ABTestBooking.group === 'D' && <QuickBookingRestB {...props} />}
    </ErrorBoundary>
  )
}

export default QuickBookingRest
