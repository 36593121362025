import { TypeGlobalArgsNearestSlots } from '@widgets/Restaurant/QuickBooking/types/types'
import { API } from '@shared/api'
import { TypeExtendedScheduleDay, TypeExtendedScheduleTimeSlot, TypeDateTimeSlot } from '@shared/types/schedule/types'
import l10n from '@widgets/Restaurant/Booking/lib/booking-localization'
import { TypeMappingScheduleWeek } from '@shared/api/middleware/mappingAPI/restaurant/type'

// TODO: отрефакторить и упростить работу с расписанием

const addZero = (number: number): string => `${`00${number}`.slice(-2)}`

/**
 * @function getShortTextSchedule
 *    Возвращает строку о статусе работы ресторана на данный момент
 *    Например: 'Открыто c 10:00 до 20:00', 'Откроется завтра в 12:00', 'Откроется в среду в 14:00'
 */
export const getShortTextSchedule = (schedule_week) => {
  try {
    const weekDayNames = ['воскресенье', 'понедельник', 'вторник', 'среду', 'четверг', 'пятницу', 'субботу']

    const currentIndexDay = new Date().getDay()
    const isWorkToday = !!schedule_week[currentIndexDay]?.open
    if (isWorkToday)
      return `Открыто c ${schedule_week[currentIndexDay].open} до ${schedule_week[currentIndexDay].close}`

    let resultText = null

    const checkDayWork = ([indexDay, object]) => {
      if (object.open && !resultText) {
        if (Number(indexDay) === currentIndexDay + 1) {
          resultText = `Откроется завтра в ${object.open}`
        } else {
          const dayText = [2, 4, 5].includes(Number(indexDay))
            ? weekDayNames[indexDay].slice(0, -1) + 'у'
            : weekDayNames[indexDay]
          resultText = `Откроется ${Number(indexDay) === 1 ? 'во' : 'в'} ${dayText} в ${object.open}`
        }
      }
    }

    // конец текущей недели
    const endCurrentWeek = Object.entries(schedule_week).splice(currentIndexDay, 6)

    // начало следующей недели
    const startCurrentWeek = Object.entries(schedule_week).splice(0, currentIndexDay)

    endCurrentWeek.forEach(checkDayWork)
    startCurrentWeek.forEach(checkDayWork)

    return resultText
  } catch (e) {
    console.error(e)
    return null
  }
}

type TypeGenerateDatesOptions = {
  preferredDay?: Date
  countDays?: number

  // Можно передать только один аргумент
  // countNearDays?: number
  // day?: Date
  // period?: [Date, Date]
}
/**
 * @function generateDatesOptions
 *    Создание расширенного массива из объектов о днях для бронирования
 */
export const generateDatesOptions = (
  restSchedule: TypeMappingScheduleWeek,
  options?: TypeGenerateDatesOptions,
): TypeExtendedScheduleDay[] => {
  const optionsDaysCount = options?.countDays || (options?.preferredDay ? 1 : 7)
  const nearDays10template = Array.from(Array(optionsDaysCount).keys())

  const outputDates = Array.from(nearDays10template, (dayGapCount) =>
    generateDateOption(dayGapCount, restSchedule, options?.preferredDay),
  )

  return [...outputDates]
}

/**
 * @function generateDateOption
 *    Получение расширенного объекта дня
 */
export const generateDateOption = (
  daysGap = 0,
  restSchedule: TypeMappingScheduleWeek,
  preferredDay?: Date,
): TypeExtendedScheduleDay => {
  const thisDate = preferredDay || new Date()

  thisDate.setDate(thisDate.getDate() + daysGap)
  thisDate.setHours(0)
  thisDate.setMinutes(0)

  const fullYear = thisDate.getFullYear()
  const monthStr = l10n('months_arr')[thisDate.getMonth()]

  const weekDayIndex = thisDate.getDay()
  const weekDayStr = l10n('weeks_abbr_arr')[weekDayIndex]

  const dayNumber = thisDate.getDate()
  const zeroMonth = addZero(thisDate.getMonth() + 1)
  const zeroDay = addZero(dayNumber)

  const outputSchedule = !restSchedule ? null : restSchedule[weekDayIndex]

  const openTime = !outputSchedule?.open ? null : outputSchedule.open.split(':')
  const openRestDate = !openTime
    ? null
    : new Date(new Date(new Date(thisDate).setHours(Number(openTime[0]))).setMinutes(Number(openTime[1])))
  const isRestOpened = !!(!restSchedule || restSchedule[weekDayIndex]?.open)
  return {
    itemText: `${weekDayStr}, ${dayNumber} ${monthStr}`,
    itemTextShort: `${dayNumber} ${monthStr}`,
    schedule: outputSchedule,
    openRestDate: openRestDate ? openRestDate.toString() : null,
    value: `${fullYear}-${zeroMonth}-${zeroDay}`,
    thisDate: thisDate.toString(),
    isRestOpened: isRestOpened,
  }
}

/**
 * @function generateTimeOptions
 *    TODO: описание
 */
export const generateTimeOptions = (selectedDayOption: TypeExtendedScheduleDay): TypeExtendedScheduleTimeSlot[] => {
  if (!selectedDayOption.isRestOpened) return null

  const slotDurationMS = (selectedDayOption.schedule.slotDuration || 30) * 60 * 1000

  const generatedTimeArray = selectedDayOption.schedule.slots.map((slot) => {
    const [hours, minutes] = slot.time.split(':')
    const dateSlot = new Date(selectedDayOption.thisDate)
    if (slot.isNextDay) dateSlot.setDate(dateSlot.getDate() + 1)

    dateSlot.setHours(Number(hours))
    dateSlot.setMinutes(Number(minutes))

    return { value: slot.time, date: dateSlot.toJSON() }
  })

  return generatedTimeArray.filter((slot) => {
    const todayUserDate = new Date()
    const dateSlot = new Date(slot.date)
    const isToday = dateSlot.toLocaleDateString() === todayUserDate.toLocaleDateString()

    if (!isToday) return true
    // Ближайший слот должен быть не раньше чем: временной отрезок слота + текущее время
    // Пример: текущее время - 20:03, временной отрезок слота - 30 минут = ближайший слот не раньше 20:33, следовательно - 21:00
    else return dateSlot.getTime() > todayUserDate.getTime() + slotDurationMS
  })
}

/**
 * @function getNearestSlots
 *    Получаем данные первых двух дней (с запасом), когда ресторан открыт.
 *    На основе этого генерим расписание слотов для брони
 */
export const getNearestSlots = async (data: TypeGlobalArgsNearestSlots): Promise<TypeDateTimeSlot[]> => {
  const dataFormatted = {
    restData: data.restData || null,
    guests: data.guests || 2,
    settings: data.settings ? { maxNearestDaysRequests: 3, ...data.settings } : { maxNearestDaysRequests: 3 },
  }
  const { schedule, booking, url } = dataFormatted.restData

  const daysOpened = generateDatesOptions(schedule.week)

  const hasRemarked = booking.afisha.remarked.active
  const optionsRemarkedRequest = {
    dates: daysOpened,
    restSlug: url.slug,
    guests: dataFormatted.guests,
    maxNearestDaysRequests: dataFormatted.settings.maxNearestDaysRequests,
  }
  return hasRemarked ? await getNearestRemarkedSlots(optionsRemarkedRequest) : getNearestScheduleSlots(daysOpened)
}

/**
 * @function getScheduleDaySlots
 *    TODO: описание
 */
export const getScheduleDaySlots = (day: TypeExtendedScheduleDay): TypeDateTimeSlot[] => {
  try {
    return generateTimeOptions(day).map((timeSlot) => ({ day: day, time: timeSlot }))
  } catch (e) {
    return []
  }
}

/**
 * @function getNearestScheduleSlots
 *    TODO: описание
 */
export const getNearestScheduleSlots = (dates: TypeExtendedScheduleDay[]) => {
  for (const day of dates) {
    const timeOptions = getScheduleDaySlots(day)
    if (timeOptions?.length && timeOptions?.length >= 2) {
      return timeOptions
    }
  }
  return []
}

type TypeGetNearestRemarkedSlots = {
  dates: TypeExtendedScheduleDay[]
  restSlug: string
  guests: number
  // максимальное кол-во дней будет проверено, чтобы найти ближайший день с открытыми слотами
  maxNearestDaysRequests?: number
}
/**
 * @function getNearestRemarkedSlots
 *    Получение ближайших слотов из ремаркеда
 */
export const getNearestRemarkedSlots = async ({
  dates,
  restSlug,
  guests,
  maxNearestDaysRequests = 3,
}: TypeGetNearestRemarkedSlots): Promise<TypeDateTimeSlot[]> => {
  for (const dateIndex of Array.from(Array(maxNearestDaysRequests).keys())) {
    const arg = { date: dates[dateIndex], restSlug, guests }
    const response = await fetchDayRemarkedSlots(arg)
    if (response?.length && response?.length >= 2) {
      return response
    }

    if (dateIndex === maxNearestDaysRequests) return null
  }
}

type TypeFetchDaySlots = {
  date: TypeExtendedScheduleDay
  restSlug: string
  guests: number
}
/**
 * @function fetchDayRemarkedSlots
 *    Запрос слотов на день из ремаркеда
 */
export const fetchDayRemarkedSlots = async (data: TypeFetchDaySlots): Promise<TypeDateTimeSlot[]> => {
  try {
    const response = await API.booking_get_slots({
      numOfPlaces: data.guests || 2,
      date: data.date.value,
      rest: data.restSlug,
    })

    return response?.slots?.map((timeSlot) => ({ day: data.date, time: timeSlot })) || null
  } catch (e) {
    return null
  }
}
