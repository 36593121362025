import React, { FC } from 'react'
import s from './Place.module.sass'
import { Metro } from '@shared/ui/Feedback/Metro'
import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TypeMappingRestMetroItem } from '@shared/api/middleware/mappingAPI/restaurant/type'

interface Props {
  metro?: TypeMappingRestMetroItem[]
  address: string
}

const Place: FC<Props> = ({ metro, address }) => {
  return metro || address ? (
    <ErrorBoundary>
      <div className={s.metro}>
        {metro?.length > 0 ? (
          metro.map((item, index) => {
            const isShowStation = index < 2
            return isShowStation && <Metro key={item.name} name={item.name} link={item.url} colors={item.color} />
          })
        ) : (
          <Text tag={'span'} sizes={'S'} className={s.address}>
            {address}
          </Text>
        )}
      </div>
    </ErrorBoundary>
  ) : null
}

export default Place
