import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import { useKeenSlider } from 'keen-slider/react'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import s from '@shared/ui/Layout/Gallery/Gallery.module.sass'

interface Props {
  className?: string
}

const Skeleton: FC<Props> = ({ className = '' }) => {
  const { isDesktopLarge, isMobile } = useClientResponsive()
  const skeletonList = Array.from(Array(5).keys())
  const [sliderRef] = useKeenSlider({
    disabled: !isMobile,
    slides: {
      perView: 2.3,
      spacing: 8,
    },
    breakpoints: {
      '(min-width: 500px)': {
        slides: {
          perView: 3.3,
          spacing: 8,
        },
      },
      '(min-width: 1280px)': {
        slides: {
          perView: 4,
          spacing: 8,
        },
      },
      '(min-width: 1592px)': {
        slides: {
          perView: 5,
          spacing: 16,
        },
      },
    },
  })

  return (
    <ErrorBoundary>
      <div className={s.wrapper}>
        <div ref={sliderRef} key={'skeleton-gallery'} className={`${s.container} ${className} keen-slider`}>
          {skeletonList.map(
            (el, idx) =>
              idx <= (isDesktopLarge ? 4 : 3) && (
                <div key={idx} className={`${s['media-item']} ${s['media-item-skeleton']} keen-slider__slide`}>
                  <ContentLoader
                    speed={SKELETON_SPEED}
                    backgroundColor={SKELETON_MAIN_COLOR}
                    foregroundColor={SKELETON_FOREGROUND_COLOR}
                    style={SKELETON_STYLES}
                    width='100%'
                    height='100%'
                    key={idx}>
                    <rect x='0' y='0' rx='16' ry='16' width='100%' height='100%' />
                  </ContentLoader>
                </div>
              ),
          )}
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
