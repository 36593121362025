import MenuImage1 from '@images/menu-items/desktop/menu-image-1.png'
import MenuImage2 from '@images/menu-items/desktop/menu-image-2.png'
import MenuImage4 from '@images/menu-items/desktop/menu-image-4.png'
import MenuImage7 from '@images/menu-items/desktop/menu-image-7.png'
import MenuImage8 from '@images/menu-items/desktop/menu-image-8.png'
import MenuImage3 from '@images/menu-items/desktop/menu-image-3.png'
import MenuImage6 from '@images/menu-items/desktop/menu-image-6.png'
import MenuImage9 from '@images/menu-items/desktop/menu-image-9.png'
import MenuImage5 from '@images/menu-items/desktop/menu-image-5.png'

import { TypeMappingRestDish, TypeMappingRestMetroItem } from '@shared/api/middleware/mappingAPI/restaurant/type'

/**
  Маппинг данных блюд
*/
export const generateNewDishes = (data): TypeMappingRestDish[] => {
  try {
    return data?.items?.map((dishItem, i) => ({
      id: dishItem.id || i,
      title: dishItem.title,
      price: dishItem.price,
      img: dishItem.photo,
      description: dishItem.description,
    }))
  } catch (e) {
    console.log(e)
    return null
  }
}

/**
 Преобразование из "+8(111) 111 11 11, +8(222) 222 22 22" в ['+8(111) 111 11 11', '+8(222) 222 22 22']
 */
export const genPhoneArray = (phoneData): string[] => {
  try {
    return !(typeof phoneData === 'string') ? phoneData : phoneData.split(',').map((phone) => phone.trim())
  } catch (e) {
    console.log(e)
    return null
  }
}

/**
  Проверка на наличие текста, удаление пробелов
*/
export const isDescriptionExist = (data: string): string | null => {
  try {
    const regExp = /[а-яА-ЯёЁa-zA-Z]/g
    const textExist = !!data && regExp.test(data)
    return textExist ? data.trim() : null
  } catch (e) {
    console.log(e)
    return null
  }
}

export const getValueDiscount = (string) => {
  try {
    return string ? string.match(/\d+/i)[0] : null
  } catch (e) {
    console.log(e)
    return null
  }
}

export const generateMenuPDF = (menuArray) => {
  try {
    const menuTemplate = {
      breakfast: {
        img: MenuImage1.src,
        keyWords: ['Завтрак', 'breakfast'],
      },
      bar: {
        img: MenuImage2.src,
        keyWords: ['Бар', 'Барная карта', 'bar'],
      },
      child: {
        img: MenuImage4.src,
        keyWords: ['Детское', 'child'],
      },
      main: {
        img: MenuImage7.src,
        keyWords: ['Основное меню', 'Главное', 'Main'],
      },
      lunch: {
        img: MenuImage8.src,
        keyWords: ['Обед', 'Ланч', 'lunch'],
      },
      cocktails: {
        img: MenuImage3.src,
        keyWords: ['Коктейл', 'cocktail'],
      },
      wine: {
        img: MenuImage6.src,
        keyWords: ['Вино', 'Винная карта', 'Винное', 'wine'],
      },
      tea: {
        img: MenuImage9.src,
        keyWords: ['Чай', 'tea'],
      },
      other: {
        img: MenuImage5.src,
        keyWords: null,
      },
    }

    if (!menuArray?.length) return null

    let countMainMenu = 0
    for (let i = 0; i < menuArray.length; i++) {
      const getMenuTemplate =
        Object.values(menuTemplate).find((el) => {
          return el.keyWords && el.keyWords.some((word) => menuArray[i].type.toLowerCase().includes(word.toLowerCase()))
        }) || menuTemplate.other

      menuArray[i] = {
        id: i,
        type: menuArray[i].type,
        href: menuArray[i].href?.replace('//develop.rests', '//rests'),
        img: getMenuTemplate.img,
      }

      // Выносим основное меню в начало списка
      // Если в списке есть несколько основных меню (напр. ру. и англ.) то выносятся вперед оба
      const isMainMenu = menuTemplate.main.keyWords.some((word) =>
        menuArray[i].type.toLowerCase().includes(word.toLowerCase()),
      )

      if (isMainMenu) {
        const temporary = menuArray[countMainMenu]
        menuArray[countMainMenu] = menuArray[i]
        menuArray[i] = temporary
        countMainMenu += 1
      }
    }

    return menuArray
  } catch (e) {
    console.log(e)
    return null
  }
}

export const mappingRestMetro = (metroList): TypeMappingRestMetroItem[] | null => {
  try {
    const prepareMetroColors = (colorValue) => {
      const defaultMetroColor = '262626'
      return Array.isArray(colorValue)
        ? [...colorValue.map((thisColor) => `#${thisColor || defaultMetroColor}`)]
        : [`#${colorValue || defaultMetroColor}`]
    }

    if (metroList?.length) {
      return metroList.map((item) => {
        const { hex_color, ...other } = item
        return { ...other, color: prepareMetroColors(hex_color) }
      })
    } else return null
  } catch (e) {
    console.log(e)
    return null
  }
}
