import React, { FC, useRef } from 'react'
import s from './QuickBookingArticleC.module.sass'
import { Title } from '@shared/ui/Typography/Title'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { QuickBookingSelectors } from '@widgets/Restaurant/QuickBooking/components/QuickBookingSelectors'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import Link from 'next/link'
import { EGG } from '@shared/api/analytics'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'

interface Props {
  restData: TypeMappingRestOutput
  className?: string
}

const QuickBookingArticleC: FC<Props> = ({ restData, className = '' }) => {
  const hasBookingAfisha = restData.booking.afisha.active
  const refLink = useRef()
  useIntersectionAnalytic(refLink, () => EGG.entity.news.rest_name_link_view(restData, { section_name: 'widget' }))
  const handleClickLinkTitle = () => {
    EGG.entity.news.rest_name_link_click(restData, { section_name: 'widget' })
  }

  if (!hasBookingAfisha) return
  const handleSelectGuest = (data, value) => {
    EGG.entity.booking.selectors_quick_booking_select_guest(data, { input: value })
  }

  const handleSelectDate = (data, value) => {
    EGG.entity.booking.selectors_quick_booking_select_date(data, { input: value })
  }
  const handleSelectTime = (data, value) => {
    EGG.entity.booking.selectors_quick_booking_select_time(data, { input: value })
  }

  const handleOpenBooking = (data) => {
    EGG.entity.booking.selectors_quick_booking_apply_click(data)
  }
  return (
    <ErrorBoundary>
      <div className={`${s.widget} ${className}`}>
        <Title sizes={'h3 h5'} className={s.title}>
          Забронировать столик в{' '}
          <Link
            href={restData.url.path}
            target={'_blank'}
            className={s['rest-title']}
            ref={refLink}
            onClick={handleClickLinkTitle}>
            {restData.name}
          </Link>
        </Title>

        <QuickBookingSelectors
          restData={restData as TypeMappingRestOutput}
          classNameWrapper={s.selectors}
          onSelectGuest={handleSelectGuest}
          onSelectDate={handleSelectDate}
          onSelectTime={handleSelectTime}
          onOpenBooking={handleOpenBooking}
        />
      </div>
    </ErrorBoundary>
  )
}

export default QuickBookingArticleC
