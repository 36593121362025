import React from 'react'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'
import s from './RestReviews.module.sass'
import { useRouter } from 'next/router'
import { Reviews } from '@entities/Restaurant/Reviews'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Title } from '@shared/ui/Typography/Title'
import { loadMoreReviews, loadPageReviews } from '@entities/Restaurant/Reviews/model/createAsyncThunk'
import { ErrorBoundary } from '../../6_shared/lib/components/ErrorBoundary'
import { TypeMappingReviewOutput } from '@shared/api/middleware/mappingAPI/review/type'
import { Pagination } from '@features/Common/Pagination'
import { ContainerTwoColumns } from '@shared/ui/Layout/Containers/ContainerTwoColumns'
import Link from 'next/link'
import { AdvertSidebarSticky } from '@shared/ui/Layout/AdvertTemplate/ui/AdvertSidebarSticky'
import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'

interface RestItemProps {
  data: TypeMappingRestOutput
  shouldOpenBooking: boolean
  slug: string
  restId: string
  url_slug: string
  global_data: object
  initial_data: object
  rest_item_data: object
  router_info: object
}

const RestReviews = (props: RestItemProps) => {
  const router = useRouter()
  const stateReviews = useAppSelector((state) => state.entities.restaurant_reviews)
  const { user_reviews, other_reviews } = stateReviews.lists
  const dispatch = useAppDispatch()
  const { isMobile, isTablet } = useClientResponsive()
  const selectFilter = stateReviews.filters.rates.find((filter) => filter.select)
  const userReviews = user_reviews.filter((review: TypeMappingReviewOutput) => {
    const isLike = selectFilter.type === 'positive' && review.like
    const isDislike = selectFilter.type === 'negative' && !review.like
    const isAll = selectFilter.type === 'all'

    return isLike || isDislike || isAll
  })

  useEffectOnce(() => {
    // @ts-ignore
    if (props?.hasRedirect?.state) {
      // @ts-ignore
      router.replace(props?.hasRedirect.path)
    }
    // @ts-ignore
  }, [])

  if (!props.data) return

  return (
    <ErrorBoundary>
      <main>
        <ContainerTwoColumns className={s.container}>
          {/*@ts-ignore*/}
          <ContainerTwoColumns.Main>
            <Reviews restaurant={{ name: props.data.name, id: props.data.id }} reviewsOptions={{ pageSize: 5 }}>
              <Title tag={'h1'} sizes={'h2 h3'} className={s.title}>
                <Link href={props.data.url.path} className={s['title-link']}>
                  {props.data.name}
                </Link>
                , отзывы
              </Title>

              {(isMobile || isTablet) && <Reviews.Create mode={'modal'} />}
              <div className={s.filters}>
                <Reviews.Filters />
              </div>

              {(!!other_reviews.length || !!userReviews.length) && (
                <div className={s.list}>
                  {userReviews?.map((review) => (
                    <Reviews.Item key={review.id} data={review} mode={'row'} />
                  ))}
                  {other_reviews?.map((item) => (
                    <Reviews.Item key={item.id} data={item} mode={'row'} />
                  ))}
                </div>
              )}

              <Pagination
                mode={isMobile ? 'load-more' : 'all'}
                autoloadingOnMobile={true}
                currentPage={stateReviews.pagination?.page.current.number}
                pageCount={stateReviews.pagination?.page.count}
                handleLoadMore={() => dispatch(loadMoreReviews())}
                handleSelectPage={(number) => dispatch(loadPageReviews({ page: number }))}
                analytic={{ section_name: 'Отзывы ресторана' }}
                className={s.pagination}
              />
            </Reviews>
            {/*@ts-ignore*/}
          </ContainerTwoColumns.Main>

          {!isMobile && !isTablet && (
            //@ts-ignore
            <ContainerTwoColumns.Aside>
              {/*@ts-ignore*/}
              <AdvertTemplate type={'240x400'} container={AdvertSidebarSticky} />
              {/*@ts-ignore*/}
            </ContainerTwoColumns.Aside>
          )}
        </ContainerTwoColumns>
      </main>
    </ErrorBoundary>
  )
}

export default RestReviews
