import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { loadReviewsLists } from '@entities/Restaurant/Reviews/model/createAsyncThunk'
import {
  hideComponentCreate,
  resetStateReviews,
  setParamsRequest,
  setReadyWidget,
  setRestaurant,
  showComponentCreate,
} from '@entities/Restaurant/Reviews/model/reducer'
import { globalStore } from '@app/model/store'

export const initReviews =
  (restaurant: { id: number; name: string }, options: { pageSize: number }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    dispatch(resetStateReviews())
    dispatch(setRestaurant(restaurant))
    dispatch(setParamsRequest({ restaurant: restaurant.id, page_size: options?.pageSize || 10 }))
    // @ts-ignore
    await dispatch(loadReviewsLists())
    // @ts-ignore
    dispatch(initSendReviews())
    dispatch(setReadyWidget(true))
  }

export const updateStatusReviewProcessed = () => {
  const store = globalStore.getState()
  const stateReviews = store.entities.restaurant_reviews
  const getReviewUser = stateReviews.lists.user_reviews.length
  const reviewInProcess = localStorage.getItem('я')
  const currentUserReviewInProcess = reviewInProcess
    ? // @ts-ignore
      JSON.parse(reviewInProcess).user_id === store.global.user.data?.id
    : false

  if (currentUserReviewInProcess && getReviewUser) {
    localStorage.removeItem('review-process')
  }
}

export const initSendReviews = () => (dispatch: Dispatch<AnyAction>) => {
  updateStatusReviewProcessed()
  const store = globalStore.getState()
  const stateReviews = store.entities.restaurant_reviews
  const isUserAuth = store.global.user.data
  const userHasReviews = stateReviews.lists.user_reviews?.length
  const reviewInProcess = localStorage.getItem('review-process')

  let currentUserReviewInProcess = false
  try {
    const jsonParse = JSON.parse(reviewInProcess)
    currentUserReviewInProcess =
      jsonParse.user === store.global.user.data?.afisha_id && jsonParse.restaurant === stateReviews.restaurant.id
  } catch {
    currentUserReviewInProcess = false
  }

  if (!isUserAuth) {
    return dispatch(showComponentCreate({ id: stateReviews.restaurant.id, body: '', is_like: null }))
  }

  if (userHasReviews || currentUserReviewInProcess) {
    dispatch(hideComponentCreate())
  } else {
    dispatch(showComponentCreate({ id: stateReviews.restaurant.id, body: '', is_like: null }))
  }
}
