import { CityRetrieve } from '@shared/api/types/swaggerTypes'
import { TypeMappingCityReadOutput } from '@shared/api/middleware/mappingAPI/city/types'
import { QUICK_FILTERS_BY_CITIES } from '@shared/api/middleware/mappingAPI/city/constQuickFilters'
import { cityIn } from 'lvovich'

export const getMappingCityRead = (object: CityRetrieve): TypeMappingCityReadOutput => {
  // @ts-ignore
  return {
    ...object,
    prepositional_case: cityIn(object.name),
    quick_filters: QUICK_FILTERS_BY_CITIES[object.slug] || null,
  }
}
