import { TypeMappingScheduleSlot, TypeMappingScheduleWeek } from '@shared/api/middleware/mappingAPI/restaurant/type'

/**
 * @function generateScheduleWeek
 *    Возвращает объект с 7 элементами
 *    Ключи - дни недели
 *    Значение - расписание работы в указанный день
 */
export const mappingScheduleWeek = (
  workingHours,
  slotDuration: 5 | 10 | 20 | 30 | 60 = 30,
): TypeMappingScheduleWeek => {
  const weekDayTemplate = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота']
  const result = {}

  weekDayTemplate.forEach((day, index) => {
    const sourceDay = workingHours.find((el) => el.day === day)
    const cycleResult = {
      weekdayIndex: index,
      weekday: day,
      slotDuration: null,
      open: null,
      close: null,
      slots: null,
    }

    if (sourceDay) {
      const open = sourceDay.time_start?.slice(0, 5) || null
      // null может прийти с бека как обозначение о работе ресторана до последнего клиента
      // в этом случае работа ресторана условно заканчивается в 22:00
      const close = sourceDay.time_end ? sourceDay.time_end.slice(0, 5) : '22:00'

      cycleResult.open = sourceDay.time_start?.slice(0, 5) || null
      cycleResult.close = sourceDay.time_end ? sourceDay.time_end.slice(0, 5) : '22:00'
      cycleResult.slots = generateSlotsByScheduleDay(open, close, slotDuration)
      cycleResult.slotDuration = cycleResult.slots?.length ? slotDuration : null
    }

    result[index] = cycleResult
  })
  return result
}

/**
 * @function generateSlotsByScheduleDay
 * Генерация слотов на основе времени открытия и закрытия.
 * формат аргументов open,close = 'ЧЧ:ММ', gapMinutes = MM
 * */
const generateSlotsByScheduleDay = (open, close = '22:00', gapMinutes = 30): TypeMappingScheduleSlot[] => {
  try {
    if (!open || !close) return null
    const gapMillisecond = gapMinutes * 60 * 1000

    const [openingHour, openingMinutes] = open.split(':').map((s) => Number(s))
    const [closingHour, closingMinutes] = close.split(':').map((s) => Number(s))

    let openingDate = new Date(null, null, null, openingHour, openingMinutes)
    const closingDate = new Date(null, null, null, closingHour, closingMinutes)

    if (closingDate.getTime() <= openingDate.getTime()) {
      closingDate.setDate(closingDate.getDate() + 1)
    }

    // Корректируем время открытия (если время открытия не стандартное)
    // например если время открытия 8:25, то будет первый слот будет назначен на 18:30 при gapMinutes = 30
    Array.from(Array(60 / gapMinutes).keys()).forEach((el, index) => {
      // Если минуты открытия попадают в слот, то ничего не делаем (напр: для слота в 30 минут это 12:00 и 12:30)
      if (openingDate.getMinutes() % gapMinutes === 0) return

      const comparisonDate = new Date(openingDate)
      comparisonDate.setMinutes(gapMinutes * (index + 1))

      // Если минуты открытия не попадают в слот, то проверяем является ли ближайший слот поля comparisonDate больше чем openingDate
      // Если да, то comparisonDate становится датой для первой брони
      if (comparisonDate > openingDate) openingDate = comparisonDate
    })

    const result = []
    const temporaryDate = new Date(openingDate)
    // проверка прошло ли 24 для избежания ошибок и правильной обработки круглосуточных расписаний
    const hasBeen24Hours = () => temporaryDate.getTime() >= openingDate.getTime() + 1000 * 60 * 60 * 24

    while (!hasBeen24Hours() && closingDate.getTime() >= temporaryDate.getTime() + gapMillisecond) {
      const setHours = `00${temporaryDate.getHours()}`.slice(-2)
      const setMinutes = `00${temporaryDate.getMinutes()}`.slice(-2)

      // Если время слота переходит на след день (напр: 00:30), то указываем это флагом isNextDay
      const isNextDay = Number(temporaryDate.getHours()) < Number(openingDate.getHours())

      result.push({ time: `${setHours}:${setMinutes}`, isNextDay: isNextDay })
      temporaryDate.setMinutes(temporaryDate.getMinutes() + gapMinutes)
      // На всякий случай)
      if (result.length > 200) break
    }

    return result
  } catch (e) {
    console.log(e)
    return null
  }
}
